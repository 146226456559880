import React, { useEffect } from "react";
import { FC } from "react";
import { useSearchParams } from "react-router-dom";
import Loader from "../Loader/Loader";
import { TranscarentSsoControllerService } from "@9amhealth/openapi";
import Translate from "../Translate/Translate";
import styled from "@emotion/styled";
import { authenticationState } from "src/state/state";

const NiceError = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;

  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    padding: 1rem;
    font-weight: 400;
  }

  em {
    font-style: italic;
  }

  pre {
    font-size: 1rem;
    padding: 1rem;
    background-color: rgb(240, 240, 240);
    border-radius: 0.5rem;
    max-width: 90vw;
    text-align: left;
  }
`;

const HandleOneTimeToken: FC = () => {
  const [searchParams] = useSearchParams();
  const [error, setError] = React.useState<string | null>(null);
  const [response, setResponse] = React.useState<unknown | null>(null);
  const token = searchParams.get("token");

  useEffect(() => {
    if (token) {
      void TranscarentSsoControllerService.exchangeToken({ token })
        .then((res) => {
          void authenticationState
            .setCredentials({
              accessToken: res.data.accessToken,
              refreshToken: res.data.refreshToken
            })
            .then(() => {
              window.location.href = "/app/home";
            })
            .catch(() => {
              setError("Could not set credentials");
            });
        })
        .catch((err: unknown) => {
          setResponse(err);
          if (err instanceof Error) {
            setError(err.message);
          }
        });
    }
  }, [token]);

  if (error) {
    return (
      <NiceError>
        <h1>
          <Translate msg="auth.ott.error" />
        </h1>
        <em>response:</em>
        <pre>{JSON.stringify(response, null, 2)}</pre>
      </NiceError>
    );
  }

  return <Loader active fixed />;
};

export default HandleOneTimeToken;
