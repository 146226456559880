import styled from "@emotion/styled";
import type { ReactNode } from "react";
import React, { useMemo } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { signupHeaderHeightMobile } from "src/constants/layout";
import Path from "src/constants/path";
import { createAppPath } from "src/lib/createAppPath";
import translate from "src/lib/translate";
import useSamlAuth from "src/lib/useSamlAuth";
import SignupCubit from "src/state/SignupCubit/SignupCubit";
import { BlocProvider } from "src/state/state";
import RegisterPage from "src/ui/components/FunnelAuthStep/components/RegisterPage";
import HandleOneTimeToken from "src/ui/components/HandleOneTimeToken/HandleOneTimeToken";
import LoginForm from "src/ui/components/LoginForm/LoginForm";
import RegisterForm from "src/ui/components/RegisterForm/RegisterForm";
import ResetPasswordForm from "src/ui/components/ResetPasswordForm/ResetPasswordForm";
import SignupHeader from "src/ui/components/SignupHeader/SignupHeader";
import ErrorBox from "src/ui/components/StyledComponents/ErrorBox";
import WithAuth from "src/ui/components/WithAuth/WithAuth";
import { Pad } from "src/ui/styled/Pad";

const AuthWrapper = styled.div`
  min-height: "100vh";
  overflow: auto;
  padding-bottom: "0";
  padding: calc(
      ${signupHeaderHeightMobile}px + 3em + var(--ion-safe-area-top, 0px)
    )
    0 0;
  width: "100%";
`;

export function AuthPage(): ReactNode {
  const signupCubit = useMemo(() => new SignupCubit(), []);
  const navigate = useNavigate();

  const handleSuccess = (): void => {
    navigate(createAppPath(Path.app, "root"));
  };

  const { error: samlError, loading: samlLoading } = useSamlAuth();

  if (samlLoading) {
    return null;
  }

  return (
    <BlocProvider bloc={signupCubit}>
      <Routes>
        <Route
          path={Path.authLogin}
          element={
            <AuthWrapper>
              <Pad>
                <SignupHeader />
                {samlError && (
                  <div style={{ maxWidth: 460, margin: "0 auto" }}>
                    <ErrorBox data-severity="error">
                      {translate(samlError)}
                    </ErrorBox>
                    <nine-spacer s="lg"></nine-spacer>
                  </div>
                )}
                <LoginForm
                  resetPasswordLink={createAppPath(
                    Path.authResetPassword,
                    "auth"
                  )}
                  onSuccess={handleSuccess}
                  registerLink={createAppPath(Path.join, "root")}
                />
              </Pad>
            </AuthWrapper>
          }
        />

        <Route
          path={Path.authRegister}
          element={
            <WithAuth createAnonymousUser>
              <AuthWrapper>
                <Pad>
                  <SignupHeader />
                  <RegisterForm
                    loginLink={createAppPath(Path.authLogin, "auth")}
                    onSuccess={handleSuccess}
                  />
                </Pad>
              </AuthWrapper>
            </WithAuth>
          }
        />

        <Route
          path={Path.authRegisterAlternative}
          element={
            <WithAuth createAnonymousUser>
              <RegisterPage />
            </WithAuth>
          }
        />
        <Route
          path={Path.authRegisterAlternativeCoupon}
          element={
            <WithAuth createAnonymousUser>
              <RegisterPage />
            </WithAuth>
          }
        />

        <Route path={Path.authOneTimeToken} element={<HandleOneTimeToken />} />

        <Route
          path={Path.authResetPassword}
          element={
            <AuthWrapper>
              <Pad>
                <SignupHeader />
                <ResetPasswordForm
                  onSuccess={handleSuccess}
                  loginLink={createAppPath(Path.authLogin, "auth")}
                />
              </Pad>
            </AuthWrapper>
          }
        />
      </Routes>
    </BlocProvider>
  );
}

export default AuthPage;
