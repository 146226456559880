import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { authenticationState, useBloc } from "src/state/state";
import type { TranslationKey } from "src/types/translationKey";
import BlockingLoadingOverlayController from "src/ui/components/BlockingLoadingOverlay/BlockingLoadingOverlayController";
import reportErrorSentry from "./reportErrorSentry";
import SubscriptionCubit from "src/state/SubscriptionCubit/SubscriptionCubit";

const useSamlAuth = (): { loading: boolean; error: TranslationKey | null } => {
  const { search, pathname } = useLocation();
  const [error, setError] = useState<TranslationKey | null>(null);
  const urlParams = new URLSearchParams(search);
  const token = urlParams.get("token");
  const pathMatchLogin = pathname.startsWith("/app/login");
  const navigate = useNavigate();
  const [, { getUnfinishedStepPath }] = useBloc(SubscriptionCubit);

  useEffect(() => {
    if (token && pathMatchLogin) {
      BlockingLoadingOverlayController.startLoading({
        bg: "solid"
      });

      void authenticationState
        .authWithSamlToken(token)
        .then(async () => {
          const path = await getUnfinishedStepPath();
          navigate(path ?? "/app/home?from=saml");
        })
        .catch((e: unknown) => {
          reportErrorSentry(e);
          setError("samltoken.error");
        })
        .finally(() => {
          BlockingLoadingOverlayController.endLoading();
        });
    }
  }, [token, pathMatchLogin]);

  return {
    error,
    loading: Boolean(token && pathMatchLogin)
  };
};

export default useSamlAuth;
